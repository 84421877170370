import * as React from "react";
import {Link} from "gatsby";

// styles
const pageStyles = {
	color: "#232129",
	padding: "96px",
	fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};

const paragraphStyles = {
	marginBottom: 48,
};

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>Página no encontrada</title>
			<h1 style={headingStyles}>No podimos encontrar lo que buscabas</h1>
			<p style={paragraphStyles}>
				Lo sentimos{" "}
				<span role="img" aria-label="Pensive emoji">
					😔
				</span>{" "}
				Por favor intenta otra vez.
				<div>
					<Link to="/">Ir al home</Link>.
				</div>
			</p>
		</main>
	);
};

export default NotFoundPage;
